import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemperatureService {

  constructor() { }

  cToF(celsius: number) {
    if (celsius != null) {
      var cTemp = celsius;
      var cToFahr = (cTemp * 9) / 5 + 32;
      return Math.round(cToFahr);
    }
    return null;
  };

  fToC(fahrenheit: number) {
    if (fahrenheit != null) {
      var fTemp = fahrenheit;
      var fToCel = ((fTemp - 32) * 5) / 9;
      return Number(fToCel.toFixed(2));
    }
    return null;
  };
}

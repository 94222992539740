import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss']
})
export class TableSortComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  id: string;

  @Input()
  selectedColumn: string;

  @Input()
  order: string;

  @Output()
  onOrderClick: EventEmitter<string>;

  constructor() {
    this.onOrderClick = new EventEmitter<string>();
  }

  ngOnInit() {
  }

  onClick() {
    this.onOrderClick.emit(this.id);
  }

}

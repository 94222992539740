import { FormControl, AbstractControl, ValidationErrors } from "@angular/forms";
//import { SignupService } from "../signup/signup-service";
import { map, catchError } from 'rxjs/operators';
import { Observable } from "rxjs";

export class Custom {
    
    // static isEmailExists(sService: SignupService) {
    //     return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    //         return sService.checkEmailExists(c.value)
    //         .pipe(
    //             map((e: Response) => {
    //                 return e["isEmailExists"]? { 'isEmailExists' : true} : null;
    //             }),
    //             catchError((e: Response) => {throw e;})
    //         );
    //     };
    // };

    static email(control: FormControl) {
        if (control && control.value != null && control.value != "") {
            const regx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
            if (!regx.test(control.value)) {
                return { invalidEmail: true };
            }
        }
        return null;
    };

    static isValidMobile(control: FormControl) {
        let value=control.value;
        if (value && value.length>0) {
            if (value.trim().indexOf('0')==0 || value.length<10) {
                return { invalidMobile: true };
            }
        }
        return null;
    };

}
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-can-deactivate-modal',
  templateUrl: './can-deactivate-modal.component.html',
  styleUrls: ['./can-deactivate-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input()
  messageText : string='You have unsaved edits. Do you wish to continue?';

  @Input()
  okText : string='Confirm';
  
  @Input()
  cancelText : string='Cancel';

  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit() {
  }
}

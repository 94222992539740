import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuMinimizerDirective } from './menu-minimizer/menu-minimizer.directive';
import { NumericDirective } from './host-listeners/numeric.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MenuMinimizerDirective, NumericDirective],
  exports: [MenuMinimizerDirective, NumericDirective]
})
export class AppDirectiveModule { }

import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[app-menu-minimizer-directive]'
})
export class MenuMinimizerDirective {
  
  constructor(private element: ElementRef) {}
  ngOnInit() {
    if(this.element.nativeElement.classList.contains('minimizer')){
      this.element.nativeElement.addEventListener('click', this.initializeMinimizer,true);
    }else if(this.element.nativeElement.classList.contains('header-menu-manager')){
      this.element.nativeElement.addEventListener('click', this.initializeMenuhider,true);
    }
  }
  initializeMinimizer = (e : Event) : void => {
    let parent=this.element.nativeElement.closest("nav.sidebar")
    if(parent){
      if(parent.classList.contains('minimized')){
        parent.classList.remove('minimized');
        document.body.classList.remove('sidebar-minimized');
        document.body.classList.add('sidebar-minimize-off');
      }else{
        parent.classList.add('minimized');
        document.body.classList.add('sidebar-minimized');
        document.body.classList.remove('sidebar-minimize-off');
      }
    }
  };

  initializeMenuhider = (e : Event) : void => {
      if(document.body.classList.contains('sidebar-aside')){
        document.body.classList.remove('sidebar-aside');
      }else{
        document.body.classList.add('sidebar-aside');
      }    
  };

}


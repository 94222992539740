import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { BASE_CONFIG_PROVIDER, BaseConfigProvider } from '../../../env.config';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss']
})
export class AppPaginationComponent implements OnInit {

  @Input()
  totalRecord: number = 0;

  @Input()
  pageSize: number = 0;

  @Input()
  currentPage: number = 0;

  @Output()
  onPageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  goToPage: EventEmitter<number> = new EventEmitter<number>();

  jumpToPageNumberModel: number = null;
  isValidJumpto: boolean = true;
  //search subscription
  jumpToSubject: Subject<number>;

  constructor(@Inject(BASE_CONFIG_PROVIDER) private baseConfigProvider: BaseConfigProvider) {
    this.pageSize = this.baseConfigProvider.gridPageSize;
  }

  ngOnInit() {
    this.subscribeJumpTo();
  }


  onPageIndexChange(pageIndex: number) {
    this.onPageChange.emit(pageIndex);
    this.jumpToPageNumberModel = null;
  }

  jumpToPageChange(pNum: number) {
    this.jumpToSubject.next(pNum);
  }


  subscribeJumpTo() {
    this.jumpToSubject = new Subject<number>();
    this.jumpToSubject.pipe(
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe((pageNumber: number) => {
      this.jumpToPage(pageNumber)
    });
  }

  jumpToPage(pageNum: number) {
    this.isValidJumpto = true;
    let totalPage: number = this.totalRecord / this.pageSize;
    totalPage = Math.trunc(totalPage);
    let pageNumToGo = Number(pageNum);
    if ((this.totalRecord % this.pageSize) > 0)
      totalPage = totalPage + 1;

    if (!isNaN(pageNumToGo) && pageNumToGo > 0 && pageNumToGo <= totalPage) {
      this.currentPage = pageNumToGo;
      this.goToPage.emit(this.currentPage);
    }
    else if (pageNumToGo > totalPage) {
      this.isValidJumpto = false;
    }
  }
  
  ngOnDestroy() {
    if (this.jumpToSubject)
      this.jumpToSubject.unsubscribe();
  }

}

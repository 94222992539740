import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(request, next));
    }
    private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        let token = this.authService.getLoginToken();
        //if(request.headers.get("No-Authentication")=="True"){ 
        if (token) {
            request = request.clone({
                headers: request.headers.delete("No-Authentication"),
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        } else {
            request = request.clone({
                headers: request.headers.delete("No-Authentication")
            });
        }

        return next.handle(request)
            .pipe(
                catchError(error => {
                    console.log(error);
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401 || error.status === 403) {
                            this.authService.logout();
                        }
                    }
                    return throwError(error);
                })
            ).toPromise();
    }
}
import { InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';



//API url provider
export class BaseAPIService {
    public baseAPIPath: string = environment.baseAPIPath;
}
export const BASE_API_PROVIDER = new InjectionToken<BaseAPIService>("BASE_API_PROVIDER");
export const BaseApiProvider = {
    provide: BASE_API_PROVIDER,
    useClass: BaseAPIService
};

//common global configs
export class BaseConfigProvider {
    public gridPageSize: number = environment.gridPageSize;
}
export const BASE_CONFIG_PROVIDER = new InjectionToken<BaseAPIService>("BASE_CONFIG_PROVIDER");
export const BaseConfiguration = {
    provide: BASE_CONFIG_PROVIDER,
    useClass: BaseConfigProvider
};

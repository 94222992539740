import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[app-numeric]'
})
export class NumericDirective {
  @Input()
  disabenegative : boolean =false;

  @Input()
  disabefloat : boolean =false;
  
  constructor(private element: ElementRef) { }


  @HostListener('input')
  onChange() {
    var value: string = this.element.nativeElement.value;
    if (value.endsWith('..') || (value == "00") || (value == "-.") || (value == ".-") || value.endsWith('--') || (value.length > 1 && value.endsWith('-')))
      this.element.nativeElement.value = value.slice(0, -1);

    value.includes('--') ? this.element.nativeElement.value = value.replace('--', '-') : null;
    value.includes('..') ? this.element.nativeElement.value = value.replace('..', '.') : null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13, 38, 40].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) || // Home, End, Left, Right   
      (!this.disabenegative && e.key == '-') ||
      (!this.disabefloat && e.key == '.')  
    ) {
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  // @HostListener('drop', ['$event'])
  // onDrop(event: DragEvent) {
  //   event.preventDefault();
  //   const textData = event.dataTransfer
  //     .getData('text').replace(/\D/g, '');
  //   this.element.nativeElement.focus();
  //   document.execCommand('insertText', false, textData);
  // }

}

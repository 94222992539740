import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BaseHttpClientService } from './base-http-client.service';
import { BASE_API_PROVIDER, BaseAPIService } from '../../../app/env.config';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginResponse } from '../models/login-response-model';
import { ChangePasswordRequestModel, ChangePasswordResponseModel, ForgotPasswordRequestModel, ForgotPasswordResponseModel } from '../models';
import { UserRoles } from '../../../app/shared/models/user-roles';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: BaseHttpClientService,
    private router: Router,
    @Inject(BASE_API_PROVIDER) private baseAPIProvider: BaseAPIService) { }

  login(data: any): Observable<LoginResponse> {
    return this.httpClient.post(`${this.baseAPIProvider.baseAPIPath}/api/Authentication`, data, { observe: 'response' }, false)
      .pipe(map((res: HttpResponse<LoginResponse>) => {
        return res.body;
      }));
  }

  changePassword(data: ChangePasswordRequestModel): Observable<ChangePasswordResponseModel> {
    return this.httpClient.post(`${this.baseAPIProvider.baseAPIPath}/api/ChangePassword`, data)
      .pipe(map((res: HttpResponse<ChangePasswordResponseModel>) => {
        return res.body;
      }));
  }

  forgotPassword(data: ForgotPasswordRequestModel): Observable<ForgotPasswordResponseModel> {
    return this.httpClient.post(`${this.baseAPIProvider.baseAPIPath}/api/forgetpassword`, data)
      .pipe(map((res: HttpResponse<ForgotPasswordResponseModel>) => {
        return res.body;
      }));
  }



  logout() {
    this.clearLoginData();
    this.redirectToLogin();
  }

  unauthorised() {
    this.clearLoginData();
    this.redirectTo401();
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  redirectTo401() {
    try {
      this.router.navigate(['/access-denied']);
    } catch (e) {
      console.log('ee2', e);
    }
  }

  redirectToError() {
    this.router.navigate(['/error']);
  }

  clearLoginData() {
    localStorage.clear();
  }
  getLoginToken() {
    if (localStorage.getItem("access_token")) {
      return localStorage.getItem("access_token");
    } else {
      return null;
    }
  }

  isUserLogedin() {
    return this.getLoginToken() != null;
  }

  saveLoginDetailsToLocalStorage(loginDtl: LoginResponse) {
    if (loginDtl != null) {
      localStorage.setItem("access_token", loginDtl.authenticationToken);
      localStorage.setItem("emailId", loginDtl.emailID);
      localStorage.setItem("userName", loginDtl.userName);
      localStorage.setItem("userCode", loginDtl.userCode || '');
      localStorage.setItem("loginName", loginDtl.loginName);
      localStorage.setItem("chgPwdOnNextLogin", loginDtl.chgPwdOnNextLogin || '');
      localStorage.setItem("roleDesc", loginDtl.roleDesc || '');
      localStorage.setItem("roleType", loginDtl.roleType || '');
      localStorage.setItem("roleName", loginDtl.roleName || '');
      localStorage.setItem("userId", loginDtl.userID.toString());

    }
  }

  getUserRole() {
    return localStorage.getItem("roleName");
  }

  getUserId() {
    const uid = localStorage.getItem("userId");
    if (uid) return parseInt(uid);
    return null;
  }

  getFullName() {
    return localStorage.getItem("userName");
  }
  getLoginName() {
    return localStorage.getItem("loginName");
  }

  getMenuAccesByRole(role: string) {
    switch (role) {
      case UserRoles.Admin:
        return ["company_list", "branch_list", "user_list", "miscellaneous", 'bank_list'];
      case UserRoles.Accountant:
        return ["payee_list", "payee_account_list", "payment_request", "payment_request_all", "collaboration"];
      case UserRoles.Validator:
        return ["payee_list", "payee_account_list", "payment_request", "payment_request_all","collaboration"];
        case UserRoles.Executor:
          return ["payee_list", "payee_account_list", "payment_request", "payment_request_all","collaboration","csvlogs"];
      default: return [];
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDirectiveModule } from './directives/app-directive.module';
import { MenuMinimizerDirective } from './directives/menu-minimizer/menu-minimizer.directive';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { NgbDropdownModule, NgbPopoverModule, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NumericDirective } from './directives/host-listeners/numeric.directive';
import { ConfirmModalComponent } from './components/confirm-modal/can-deactivate-modal.component';
import { AppPaginationComponent } from './components/app-pagination/app-pagination.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { LoginFooterComponent } from './components/login-footer/login-footer.component';
import { MatInputModule } from '@angular/material/input';
import { TableSortComponent } from './components/table-sort/table-sort.component';

@NgModule({
  imports: [
    CommonModule,
    AppDirectiveModule,
    NgbDropdownModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    NgbPopoverModule,
    NgbModalModule,
    NgbPaginationModule,
    MatInputModule
  ],
  entryComponents: [ConfirmModalComponent],
  declarations: [
    PageTitleComponent,
    AppPaginationComponent,
    ConfirmModalComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    TableSortComponent
  ],
  exports: [
    PageTitleComponent,
    MenuMinimizerDirective,
    NumericDirective,
    AppPaginationComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    TableSortComponent
  ]
})
export class SharedModule {

}
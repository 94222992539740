import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService, BaseHttpClientService, LoggerService } from './services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { BaseApiProvider, BaseConfiguration } from '../env.config';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AuthService,
    BaseHttpClientService,
    LoggerService,
    BaseApiProvider,
    BaseConfiguration,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule { }
